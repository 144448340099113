import { useState, useEffect, CSSProperties } from "react"
import {
    motion,
    ControlType,
    addPropertyControls,
    HTMLMotionProps,
} from "framer"

// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */

interface Props extends Omit<HTMLMotionProps<"div">, "layout"> {
    title: string
    options: any[]
    styles: any
}

export default function Select(props: Props) {
    let { styles, options, title } = props
    const css = styles.select ?? {}

    if (options.includes("{{tokens}}")) options = packages.map((p) => p.tokens)

    return (
        <select
            name={title}
            style={{
                ...css.font,
                appearance: "none",
                backgroundColor: css.background ?? "transparent",
                border: css.border ?? "none",
                color: css.color,
                outline: "none",
                display: "flex",
                width: "fit-content",
            }}
        >
            {options.map((option, key) => (
                <option value={option}>{option.toLocaleString()}</option>
            ))}
        </select>
    )
}

// controls
const propertyControls = {
    title: {
        type: ControlType.String,
        title: "Name",
    },
    options: {
        type: ControlType.Array,
        title: "Options",
        control: {
            type: ControlType.String,
        },
    },
    styles: {
        type: ControlType.Object,
        controls: {
            select: {
                type: ControlType.Object,
                controls: {
                    font: {
                        type: ControlType.Font,
                        title: "Font",
                        controls: "extended",
                    },
                    color: {
                        title: "Text",
                        type: ControlType.Color,
                        defaultValue: "#000",
                    },
                    padding: {
                        title: "Padding",
                        type: ControlType.FusedNumber,
                        toggleKey: "paddingPerSide",
                        toggleTitles: ["Padding", "Padding per side"],
                        defaultValue: 0,
                        valueKeys: [
                            "paddingTop",
                            "paddingRight",
                            "paddingBottom",
                            "paddingLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    borderObject: {
                        type: ControlType.Object,
                        title: "Border",
                        optional: true,
                        controls: {
                            borderWidth: {
                                title: "Width",
                                type: ControlType.Number,
                                displayStepper: true,
                                defaultValue: 1,
                            },
                            borderColor: {
                                title: "Color",
                                type: ControlType.Color,
                                defaultValue: "rgba(200,200,200,0.5)",
                            },
                        },
                    },
                },
            },
        },
    },
}

addPropertyControls(Select, propertyControls)

const packages = [
    {
        tokens: 100,
        price: 0.4,
        tier: 34.5,
    },
    {
        tokens: 250,
        price: 0.3,
        tier: 72,
    },
    {
        tokens: 500,
        price: 0.15,
        tier: 122,
    },
    {
        tokens: 1000,
        price: 0.12,
        tier: 197,
    },
    {
        tokens: 1500,
        price: 0.12,
        tier: 197,
    },
    {
        tokens: 2500,
        price: 0.12,
        tier: 347,
    },
    {
        tokens: 5000,
        price: 0.12,
        tier: 547,
    },
    {
        tokens: 7500,
        price: 0.12,
        tier: 722,
    },
    {
        tokens: 10000,
        price: 0.1,
        tier: 872,
    },
    {
        tokens: 15000,
        price: 0.08,
        tier: 1122,
    },
    {
        tokens: 25000,
        price: 0.08,
        tier: 1522,
    },
    {
        tokens: 50000,
        price: 0.08,
        tier: 2272,
    },
    {
        tokens: 75000,
        price: 0.07,
        tier: 2772,
    },
    {
        tokens: 100000,
        price: 0.07,
        tier: 3147,
    },
    {
        tokens: 150000,
        price: 0.07,
        tier: 3647,
    },
    {
        tokens: 250000,
        price: 0.07,
        tier: 4647,
    },
    {
        tokens: 500000,
        price: 0.07,
        tier: 6897,
    },
    {
        tokens: 750000,
        price: 0.06,
        tier: 8897,
    },
    {
        tokens: 1000000,
        price: 0.06,
        tier: 10647,
    },
]
